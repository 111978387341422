import { ILocation } from "../../../utils/interfaces/locations";

import "./LocationHeader.scss";

interface ILocationHeaderProps {
  location: ILocation;
}

const LocationHeader = ({ location }: ILocationHeaderProps) => {
  return (
    <header className="location-header-component">
      <div className="name">{location.name}</div>
      {/* <div className="address">
        <span>{`${location.address}${location.address2 ? ` ${location.address2}` : ''}`}</span>
        <span>{`${location.city}, ${location.state}`}</span>
      </div> */}
    </header>
  );
};

export default LocationHeader;
