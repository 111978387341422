import "./ProfilePhoto.scss";

interface IProfilePhotoProps {
  photoURL: string;
  firstName: string;
  lastName: string;
}

const ProfilePhoto = ({ photoURL, firstName, lastName }: IProfilePhotoProps) => {
  return (
    <div className="profile-photo-component">
      <div
        className="border"
        data-initials={`${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`}
      />
      {photoURL && <div className="image" style={{ backgroundImage: `url('${photoURL}')` }} />}
      <span>{firstName}</span>
    </div>
  );
};

export default ProfilePhoto;
