import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
  Show,
  SimpleShowLayout,
  ReferenceArrayField,
  FunctionField,
  useRecordContext,
  Labeled,
  useEditContext,
  useShowContext,
  ArrayInput,
  SimpleFormIterator,
  ArrayField,
} from "react-admin";
import { Link } from "@material-ui/core";
import { collection, getDocs, query, where } from "firebase/firestore";
import QRCode from "react-qr-code";
import { firestoreDB } from "../app";
import { ILocation } from "../utils/interfaces/locations";

const IsAdminFieldShow = () => {
  const { record } = useShowContext();
  const user = useRecordContext();
  const isAdmin = record?.admins?.includes(user.id);

  return isAdmin ? <span>Admin</span> : <></>;
};

const IsAdminFieldEdit = () => {
  const { record } = useEditContext();
  const user = useRecordContext();
  const isAdmin = record?.admins?.includes(user.id);

  return isAdmin ? <span>Admin</span> : <></>;
};

const URLField = () => {
  const record: ILocation = useRecordContext();

  if (record.slug) {
    return (
      <Labeled label="URL:">
        <Link href={`${window.location.origin}/location/${record.slug}`} target="_blank">
          {`${window.location.origin}/location/${record.slug}`}
        </Link>
      </Labeled>
    );
  }

  return null;
};

const QRField = () => {
  const record: ILocation = useRecordContext();

  if (record.slug) {
    return (
      <Labeled label="QR Code:">
        <QRCode value={`${window.location.origin}/location/${record.slug}`} size={128} />
      </Labeled>
    );
  }

  return null;
};

const validateUniqueness = async (values: any) => {
  try {
    const errors: { [key: string]: any } = {};

    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.address) {
      errors.address = "Required";
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }
    if (!values.slug) {
      errors.slug = "Required";
    }
    if (values.slug) {
      const locationQuery = query(collection(firestoreDB, "locations"), where("slug", "==", values.slug));
      const locationSnap = await getDocs(locationQuery);

      if (!locationSnap.empty) {
        const location = locationSnap.docs[0].data();

        // creating or editing
        if ((!values.id && location) || (values.id && location.id !== values.id)) {
          errors.slug = "The slug must be unique";
        }
      }
    }

    return errors;
  } catch (error) {
    console.log(error);
    alert("Something went wrong checking against other locations for the slug");
  }
};

// ============================================================================== //
// ============================== EXPORTED ITEMS ================================ //
// ============================================================================== //

export const LocationCreate = () => (
  <Create redirect="show">
    <SimpleForm validate={validateUniqueness}>
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="address2" defaultValue="" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="slug" helperText="This will be used in your QR code" />
      <ArrayInput source="questions" label="Custom Questions">
        <SimpleFormIterator inline>
          <TextInput source="text"></TextInput>
          <TextInput source="answers"></TextInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const LocationEdit = () => (
  <Edit>
    <SimpleForm validate={validateUniqueness}>
      {/* Only need id for comparison for slug in validationUniqueness() */}
      <TextInput source="id" disabled style={{ display: "none" }} />
      <TextInput source="name" />
      <TextInput source="address" />
      <TextInput source="address2" defaultValue="" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="slug" label="URL Slug" helperText="This will be used in your QR code" />
      <ReferenceArrayField reference="users" source="teammates" label="Teammates">
        <Datagrid rowClick="edit" empty={<em>No teammates.</em>}>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <IsAdminFieldEdit />
        </Datagrid>
      </ReferenceArrayField>
      <ArrayInput source="questions" label="Custom Questions">
        <SimpleFormIterator inline>
          <TextInput source="text"></TextInput>
          <TextInput source="answers" helperText="Separate with commas (,). Answers will be shown as typed."></TextInput>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const LocationShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="state" />
      <URLField />
      <QRField />
      <ReferenceArrayField reference="users" source="teammates" label="Teammates">
        <Datagrid rowClick="edit" isRowSelectable={() => false} empty={<em>No teammates.</em>}>
          <TextField source="firstName" />
          <TextField source="lastName" />
          <IsAdminFieldShow />
        </Datagrid>
      </ReferenceArrayField>
      <ArrayField source="questions" label="Custom Questions">
        <Datagrid isRowSelectable={() => false} empty={<em>No custom questions</em>}>
          <TextField source="text" />
          <TextField source="answers" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const LocationList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="address" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="state" />
      <FunctionField label="# of Admins" render={(record: ILocation) => `${record.admins?.length || 0}`} />
      <FunctionField label="# of Teammates" render={(record: ILocation) => `${record.teammates?.length || 0}`} />
    </Datagrid>
  </List>
);
