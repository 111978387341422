import { Feedback } from "./interfaces/feedback";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export const FEEDBACK: { [key: string]: Feedback } = {
  GOOD: "good",
  AVERAGE: "average",
  BAD: "bad",
};

// allows picking required props within a partial
export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type ReactSelectOption = { label: string; value: string };
