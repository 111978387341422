import { useEffect, useState } from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  SimpleForm,
  TextField,
  TextInput,
  required,
  Show,
  SimpleShowLayout,
  ReferenceArrayField,
  FunctionField,
  ReferenceInput,
  useRecordContext,
  SimpleFormIterator,
  SelectInput,
  ArrayInput,
  useNotify,
  useRedirect,
  useDataProvider,
  DataProvider,
  useChoicesContext,
  FormDataConsumer,
  useEditContext,
  useUpdate,
  useShowContext,
} from "react-admin";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { ILocation } from "../utils/interfaces/locations";
import { IUser } from "../utils/interfaces/users";

interface ILocationInputProps {
  isEdit?: boolean;
  itemId?: string;
  source?: string;
}

const fixLocationData = (data: any) => {
  // Since we're using ArrayInput, the resulting structure for location gets weird
  // Extract the ids
  const locationIds: string[] = [];
  data.locations.forEach((l: any) => {
    locationIds.push(l.locations);
  });

  return {
    ...data,
    locations: locationIds,
  };
};

// Ensures that we are keeping track of locations and their teammates and admins
const LocationSelectInput = ({ isEdit, itemId, source }: ILocationInputProps) => {
  const user: IUser = useRecordContext();
  const { availableChoices } = useChoicesContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit && availableChoices.length && itemId) {
      const location = availableChoices.find((c) => c.id === itemId);
      if (location) {
        setIsAdmin(location.admins?.includes(user.id));
      }
    }
  }, [isEdit, availableChoices, itemId, user]);

  const saveAdminStatus = async () => {
    const location = availableChoices.find((c) => c.id === itemId);
    const newLocation = { ...location, admins: location.admins || [] }; // locations don't have an admin array on creation
    const isRemoval = newLocation.admins.indexOf(user.id) > -1;
    const index = isRemoval ? newLocation.admins.indexOf(user.id) : newLocation.admins.length;
    const count = isRemoval ? 1 : 0;

    if (isRemoval) {
      newLocation.admins.splice(index, count);
    } else {
      newLocation.admins.splice(index, count, user.id);
    }

    try {
      await dataProvider.update("locations", { id: location.id, data: newLocation, previousData: location });
      notify(`User updated successfully`);
    } catch (error) {
      console.log(error);
      notify(`Something went wrong editing user`);
    }
  };

  const handleAdminStatus = () => {
    setIsAdmin(!isAdmin);
    saveAdminStatus();
  };

  if (!availableChoices.length) {
    return null;
  }

  if (isEdit) {
    return (
      <>
        <SelectInput optionText="name" source={source} choices={availableChoices} />
        <label>Is Admin?</label>
        <span>
          <input type="radio" name={`${source}isAdmin`} checked={!isAdmin} onChange={handleAdminStatus} /> No
          <input type="radio" name={`${source}isAdmin`} checked={isAdmin} onChange={handleAdminStatus} /> Yes
        </span>
      </>
    );
  }

  return <SelectInput optionText="name" />;
};

const ChooseLocationsInput = ({ isEdit }: ILocationInputProps) => (
  <ArrayInput source="locations">
    <SimpleFormIterator inline disableReordering>
      {isEdit ? (
        <FormDataConsumer>
          {({ getSource, scopedFormData }) => {
            if (getSource) {
              const source = getSource(""); // pattern `source.{index}.param`

              return (
                <ReferenceInput reference="locations" source="locations">
                  <LocationSelectInput isEdit={isEdit} itemId={scopedFormData} source={source} />
                </ReferenceInput>
              );
            }

            return null;
          }}
        </FormDataConsumer>
      ) : (
        <ReferenceInput reference="locations" source="locations">
          <LocationSelectInput />
        </ReferenceInput>
      )}
    </SimpleFormIterator>
  </ArrayInput>
);

const saveUserToLocation = async (
  user: IUser,
  isEdit: boolean,
  notify: any,
  redirect: any,
  dataProvider: DataProvider
) => {
  // update the location's teammates
  for (let i = 0; i < user.locations.length; i++) {
    const locationId = user.locations[i];
    const { data: locData }: { data: ILocation } = await dataProvider.getOne("locations", { id: locationId });
    let newLocData = locData;
    newLocData.teammates = locData.teammates ? [...locData.teammates, user.id] : [user.id];
    await dataProvider.update("locations", { id: locationId, data: newLocData, previousData: locData });
  }

  notify(`User ${isEdit ? "updated" : "created"} successfully`);

  if (!isEdit) {
    redirect("edit", "users", user.id, user);
  }
};

const ShowUploadImageField = () => {
  const { record } = useShowContext();

  return (
    <div className="profile-photo-block show">
      <label className="photo-wrapper">
        <span className="initials">{`${record.firstName[0]}${record.lastName[0]}`}</span>
        {record.photoURL && <div className="preview" style={{ backgroundImage: `url('${record.photoURL}')` }} />}
      </label>
    </div>
  );
};

const EditUploadImageInput = () => {
  const [update] = useUpdate();
  const { record } = useEditContext();
  const notify = useNotify();

  const [photo, setPhoto] = useState<string>(record.photoURL);

  const uploadPhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      alert("Something went wrong uploading your image.");
      return;
    }

    const newFilename = `profiles/photo-${record.id}`;
    const storage = getStorage();
    const storageRef = ref(storage, newFilename);

    try {
      await uploadBytes(storageRef, file);

      // 200x200 is the resize value from the firebase extension - it renames the file to include it
      const photoURL = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${newFilename}_200x200`;
      const data = { photoURL };

      setPhoto(photoURL);
      update("users", { id: record.id, data, previousData: record });
      notify("Profile image was updated");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="profile-photo-block">
      <label className="photo-wrapper">
        <span className="initials">{`${record.firstName[0]}${record.lastName[0]}`}</span>
        {photo && <div className="preview" style={{ backgroundImage: `url('${photo}')` }} />}
        <input id="photo" type="file" name="images" accept="image/png, image/jpeg" onChange={uploadPhoto} />
      </label>
    </div>
  );
};

// ============================================================================== //
// ============================== EXPORTED ITEMS ================================ //
// ============================================================================== //

export const UserCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  return (
    <Create
      transform={fixLocationData}
      mutationOptions={{
        onSuccess: (data: IUser) => saveUserToLocation(data, false, notify, redirect, dataProvider),
      }}
    >
      <SimpleForm>
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput source="phone" defaultValue="" />
        <ChooseLocationsInput />
      </SimpleForm>
    </Create>
  );
};

export const UserEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <EditUploadImageInput />
        <TextInput source="firstName" validate={[required()]} />
        <TextInput source="lastName" validate={[required()]} />
        <TextInput source="phone" />
        <ChooseLocationsInput isEdit />
      </SimpleForm>
    </Edit>
  );
};

export const UserShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <ShowUploadImageField />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phone" />
        <ReferenceArrayField reference="locations" source="locations">
          <Datagrid rowClick="edit">
            <TextField source="name" />
          </Datagrid>
        </ReferenceArrayField>
      </SimpleShowLayout>
    </Show>
  );
};

export const UserList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="phone" />
      <FunctionField label="# of Locations" render={(record: IUser) => `${record.locations?.length || 0}`} />
    </Datagrid>
  </List>
);
