import React, { useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { serverTimestamp } from "firebase/firestore";
import { addTeammateFeedback } from "../../../services/feedback";
import { FEEDBACK } from "../../../utils/constants";
import { ILocation } from "../../../utils/interfaces/locations";
import { IUser } from "../../../utils/interfaces/users";
import { Feedback } from "../../../utils/interfaces/feedback";

import "./Teammate.scss";

import LocationHeader from "../../components/LocationHeader/LocationHeader";
import ProfilePhoto from "../../components/ProfilePhoto/ProfilePhoto";

interface ITeammateLoaderData {
  location: ILocation;
  teammate: IUser;
}

const Teammate = () => {
  const navigate = useNavigate();
  const { location, teammate } = useLoaderData() as ITeammateLoaderData;

  const [feedback, setFeedback] = useState<Feedback>(FEEDBACK.GOOD);
  const [feedbackReason, setFeedbackReason] = useState<string>("");
  const feedbackRef = useRef(null);

  if (!location || !teammate) {
    // route to 404
    alert("Not found. Returning to where you came from.");
    navigate(-1);
  }

  const sendFeedback = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await addTeammateFeedback({
        userId: teammate.id,
        locationId: location.id,
        feedback,
        type: "teammate",
        reason: feedbackReason,
        created: serverTimestamp(),
      });

      alert("Your feedback was submitted. Thanks!");
      navigate(`/location/${location.slug}`);
    } catch (error) {
      console.log(error);
      alert("We had trouble subbmitting your feedback. Please try again.");
    }
  };

  return (
    <div className="page teammate">
      <LocationHeader location={location} />
      <section className="teammate-info">
        <ProfilePhoto photoURL={teammate.photoURL} firstName={teammate.firstName} lastName={teammate.lastName} />
      </section>
      <section className="teammate-feedback">
        <p>Please provide feedback for this teammate:</p>
        <form onSubmit={sendFeedback}>
          <div className="form-row">
            <label>How was their service?</label>
            <select
              className="feedback-select"
              ref={feedbackRef}
              onChange={(e) => setFeedback(e.target.value as Feedback)}
            >
              {Object.keys(FEEDBACK).map((f) => (
                <option value={f.toLowerCase()}>{f}</option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label>Any comments? (optional)</label>
            <textarea className="feedback-reason" rows={4} onChange={(e) => setFeedbackReason(e.target.value)}>
              {feedbackReason}
            </textarea>
          </div>
          <button type="submit">Confirm</button>
        </form>
      </section>
    </div>
  );
};

export default Teammate;
