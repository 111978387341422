import { useMemo, useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router";
import { Helmet } from 'react-helmet';
import { serverTimestamp } from "firebase/firestore";
import { addLocationFeedback } from "../../../services/feedback";
import { FEEDBACK, ReactSelectOption } from "../../../utils/constants";
import { Feedback, IFeedbackCustomQuestion, ILocationFeedbackAddAttrs } from "../../../utils/interfaces/feedback";
import { ILocation } from "../../../utils/interfaces/locations";
import { IUser } from "../../../utils/interfaces/users";
import { startCase, orderBy } from "lodash";

import "./Location.scss";

import Select, { components, SelectInstance } from "react-select";
import ProfilePhoto from "../../components/ProfilePhoto/ProfilePhoto";
import LocationHeader from "../../components/LocationHeader/LocationHeader";

interface ILocationLoaderData {
  location: ILocation;
  teammates: IUser[];
}

type TeammateOption = {
  label: string;
  value: string;
  image: string;
  firstName: string;
  lastName: string;
};

const { Option } = components;
const feedbackOptions = [
  { label: Object.keys(FEEDBACK)[0], value: FEEDBACK.GOOD },
  { label: Object.keys(FEEDBACK)[1], value: FEEDBACK.AVERAGE },
  { label: Object.keys(FEEDBACK)[2], value: FEEDBACK.BAD },
];

const Location = () => {
  const navigate = useNavigate();
  const { location, teammates } = useLoaderData() as ILocationLoaderData;

  const feedbackRef = useRef<SelectInstance>(null);
  const teammatesRef = useRef<SelectInstance>(null);
  const reasonRef = useRef<HTMLTextAreaElement>(null);
  const [customQuestions, setCustomQuestions] = useState<IFeedbackCustomQuestion[]>([]);

  if (!location) {
    // route to 404
    alert("Not found. Returning to where you came from.");
    navigate(-1);
  }

  const teammateOptions: TeammateOption[] = useMemo(
    () =>
      orderBy(teammates.map((t) => ({
        label: `${t.firstName} ${startCase(t.lastName)}`,
        value: t.id,
        image: t.photoURL,
        firstName: t.firstName,
        lastName: t.lastName,
      })), ['firstName']),
    [teammates]
  );

  const sendFeedback = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const data: ILocationFeedbackAddAttrs = {
        locationId: location.id,
        type: "location",
        reason: reasonRef.current?.value,
        created: serverTimestamp(),
      };

      if (feedbackRef.current) {
        data.feedback = (feedbackRef.current?.getValue()[0] as ReactSelectOption)?.value as Feedback;
      }

      const teammates = teammatesRef.current?.getValue().map((t) => (t as TeammateOption).value);
      if (teammates) {
        data.kudos = teammates;
      }

      if (customQuestions.length > 0) {
        data.customQuestions = customQuestions;
      }

      if (!data.feedback && data.kudos?.length === 0 && customQuestions.length === 0 && !data.reason) {
        alert('Please fill out the form');
        return;
      }

      await addLocationFeedback(data);
      alert("Your feedback was submitted. Thanks!");
      navigate(0);
    } catch (error) {
      console.log(error);
      alert("We had trouble subbmitting your feedback. Please try again.");
    }
  };

  return (
    <div className="page location">
      <Helmet>
        <title>Review {location.name} | Gratti</title>
        <meta
          name="description"
          content={`Submit a review for ${location.name} via Gratti.`}
        />
      </Helmet>
      <LocationHeader location={location} />
      <section className="location-feedback">
        <p className="instructions">Leave feedback for this location...</p>
        <form onSubmit={sendFeedback}>
          {/* <div className="form-row">
            <label>How was this location&apos;s service?</label>
            <Select ref={feedbackRef} options={feedbackOptions} defaultValue={feedbackOptions[0]} />
          </div> */}
          {location.questions?.map((q, index) => {
            const options = q.answers.split(",").map((value) => ({
              label: value,
              value,
            }));

            return (
              <div key={index} className="form-row">
                <label>{q.text}</label>
                <Select
                  options={options}
                  onChange={(selection) =>
                    setCustomQuestions((prev) => {
                      const customs = prev;
                      const existingIndex = customs.findIndex((prevQ) => prevQ.question === q.text);

                      if (existingIndex > -1) {
                        customs.splice(existingIndex, 1);
                      }

                      return [...customs, { question: q.text, answer: selection?.label || "" }];
                    })
                  }
                />
              </div>
            );
          })}
          <div className="form-row">
            <label>Would you like to give kudos to any teammates?</label>
            <Select
              isMulti
              ref={teammatesRef}
              className="teammates-select"
              options={teammateOptions}
              components={{
                Option: (props: any) => (
                  <Option {...props}>
                    <ProfilePhoto
                      photoURL={props.data.image}
                      firstName={props.data.firstName}
                      lastName={props.data.lastName}
                    />
                    {props.data.label}
                  </Option>
                ),
              }}
              styles={{
                option: (provided) => ({
                  ...provided,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 12,
                }),
              }}
            />
          </div>
          <div className="form-row">
            <label>Any comments?</label>
            <textarea ref={reasonRef} className="feedback-reason" rows={4} />
          </div>
          <button type="submit">Confirm</button>
        </form>
      </section>
    </div>
  );
};

export default Location;
