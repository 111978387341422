import App from "./app";
import SuperAdmin from "./superadmin";

import appRoutes from "./app/appRoutes";

const routes = [
  {
    path: "/",
    element: <App />,
  },
  ...appRoutes,
  {
    path: "/superadmin/*",
    element: <SuperAdmin />,
  },
];

export default routes;
