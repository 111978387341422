import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseConfig } from "../utils/constants";

import "./index.scss";

const firebaseApp = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(firebaseApp);

const App = () => {
  return (
    <div className="page home">
      <section>
        <h1>Welcome to Gratti!</h1>
        <p>Please scan a QR code to visit a specific service location.</p>
      </section>
    </div>
  );
};

export default App;
