import {
  collection,
  collectionGroup,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { firestoreDB } from ".";
import { ILocation } from "../utils/interfaces/locations";
import { IUser } from "../utils/interfaces/users";

import Locations from "./pages/Locations/Locations";
import Location from "./pages/Location/Location";
import Teammate from "./pages/Teammate/Teammate";

const appRoutes = [
  {
    path: "/locations",
    element: <Locations />,
    loader: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const snapshot: QuerySnapshot = await getDocs(collection(firestoreDB, "locations"));
          const locations: ILocation[] = [];
          snapshot.forEach((doc) => {
            locations.push(doc.data() as ILocation);
          });

          resolve(locations);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  {
    path: "/location/:locationSlug",
    element: <Location />,
    loader: async ({ params }: any) => {
      return new Promise(async (resolve, reject) => {
        try {
          // get location
          const locationQuery = query(collection(firestoreDB, "locations"), where("slug", "==", params.locationSlug));
          const locationSnap = await getDocs(locationQuery);
          const location = locationSnap.docs[0].data(); // slug is unique

          const teammates: IUser[] = [];
          if (location) {
            // get teammates
            const usersQuery = query(
              collectionGroup(firestoreDB, "users"),
              where("locations", "array-contains", location.id)
            );
            const usersSnap = await getDocs(usersQuery);
            usersSnap.forEach((doc) => {
              teammates.push(doc.data() as IUser);
            });
          }

          resolve({ location, teammates });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  {
    path: "/location/:locationSlug/teammate/:teammateId",
    element: <Teammate />,
    loader: async ({ params }: any) => {
      return new Promise(async (resolve, reject) => {
        try {
          // get location
          const locationQuery = query(collection(firestoreDB, "locations"), where("slug", "==", params.locationSlug));
          const locationSnap = await getDocs(locationQuery);
          const location = locationSnap.docs[0].data(); // slug is unique

          // get teammate
          const teammateRef = doc(firestoreDB, "users", params.teammateId);
          const teammateSnap: DocumentSnapshot = await getDoc(teammateRef);
          const teammate = teammateSnap.data();

          resolve({ location, teammate });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
];

export default appRoutes;
