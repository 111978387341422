import { Admin, Resource } from "react-admin";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  // FirebaseRealTimeSaga
} from "react-admin-firebase";
import { firebaseConfig } from "../utils/constants";

import "./superadmin.scss";

import { LocationCreate, LocationEdit, LocationList, LocationShow } from "./locations";
import { UserCreate, UserEdit, UserList, UserShow } from "./users";
import { FeedbackList, FeedbackShow } from "./feedback";

const options = {};
const dataProvider = FirebaseDataProvider(firebaseConfig, options);
const authProvider = FirebaseAuthProvider(firebaseConfig, options);

const SuperAdmin = () => {
  return (
    <Admin basename="/superadmin" dataProvider={dataProvider} authProvider={authProvider}>
      <Resource name="locations" list={LocationList} edit={LocationEdit} show={LocationShow} create={LocationCreate} />
      <Resource name="users" list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} />
      <Resource name="feedback" list={FeedbackList} show={FeedbackShow} options={{ label: "Feedback" }} />
    </Admin>
  );
};

export default SuperAdmin;
