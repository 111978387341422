import { getFirestore, addDoc, collection } from "firebase/firestore";
import { ITeammateFeedbackAddAttrs, ILocationFeedbackAddAttrs } from "../utils/interfaces/feedback";

const db = getFirestore();

export const addTeammateFeedback = (attributes: ITeammateFeedbackAddAttrs) => {
  return addDoc(collection(db, "feedback"), {
    ...attributes,
  });
};

export const addLocationFeedback = (attributes: ILocationFeedbackAddAttrs) => {
  return addDoc(collection(db, "feedback"), {
    ...attributes,
  });
};
