import { Chip } from "@material-ui/core";
import {
  Datagrid,
  List,
  TextField,
  Show,
  SimpleShowLayout,
  ReferenceField,
  FunctionField,
  DateField,
  ReferenceArrayField,
  useListContext,
  ArrayField,
} from "react-admin";
import { ILocationFeedback } from "../utils/interfaces/feedback";
import { ILocation } from "../utils/interfaces/locations";
import { IUser } from "../utils/interfaces/users";

export const KudosList = () => {
  const { data, isLoading } = useListContext();

  return isLoading ? null : (
    <>
      {data.map((u: IUser) => (
        <Chip label={`${u.firstName} ${u.lastName}`} />
      ))}
    </>
  );
};

export const FeedbackShow = () => (
  <Show>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="userId" />
      <TextField source="locationId" />
      <TextField source="feedback" />
      <TextField source="reason" />
      <ReferenceArrayField reference="users" source="kudos" emptyText="N/A">
        <KudosList />
      </ReferenceArrayField>
      <ArrayField source="customQuestions" label="Custom Questions">
        <Datagrid isRowSelectable={() => false} empty={<em>No custom questions</em>}>
          <TextField source="question" />
          <TextField source="answer" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export const FeedbackList = () => (
  <List>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="type" />
      <ReferenceField reference="locations" source="locationId" link="show" label="Location">
        <FunctionField source="id" render={(record: ILocation) => record.name} />
      </ReferenceField>
      <TextField source="feedback" />
      <FunctionField source="kudos" label="# of Kudos" render={(record: ILocationFeedback) => record.kudos?.length} />
      <FunctionField
        source="customQuestions"
        label="# of Custom Answers"
        render={(record: ILocationFeedback) => record.customQuestions?.length}
      />
      <DateField source="created" showTime />
    </Datagrid>
  </List>
);
